<template>
  <v-row align-content="center">
    <v-col v-show="regis" class="py-0" md="12">
      <v-row>
        <v-col class="white mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-3"></v-spacer>
          <router-link
            class="font-weight-medium text-decoration-none px-8"
            tabindex="-1"
            color="primary"
            :to="{ name: 'home' }"
          >
            <span
              ><v-icon color="primary"> mdi-arrow-left</v-icon> Kembali</span
            >
          </router-link>
          <v-spacer class="py-5"></v-spacer>
          <v-form
            class="px-8"
            @submit.prevent="submit"
            ref="form"
            lazy-validation
            v-model="valid"
          >
            <div class="fs-24 font-weight-bold mb-2">Daftar dulu biar asik</div>
            <div class="color-disable mb-4">
              Sudah mempunyai akun ?
              <router-link
                class="text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'login' }"
              >
                <span>Masuk ke akun anda</span>
              </router-link>
            </div>
            <v-alert
              type="error"
              text
              dense
              prominent
              v-show="response !== null"
              >{{ response }}</v-alert
            >
            <v-row>
              <v-col class="pb-0">
                <div class="d-sm-flex justify-space-between mb-1">
                  <div class="font-weight-medium">Nomor Whatsapp</div>
                  <div class="my-auto text-sm-right color-disable fs-12">
                    *pastikan anda memiliki akun WA
                  </div>
                </div>
                <v-text-field
                  class="rounded-xl"
                  v-model="nomer"
                  type="number"
                  placeholder="081234xxx"
                  :rules="nomerRules"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0">
                <div class="mb-1">
                  <span class="font-weight-medium">Password</span>
                </div>
                <v-text-field
                  class="rounded-xl"
                  v-model="password"
                  placeholder="Min. 8 karakter"
                  :rules="pswRules"
                  outlined
                  hide-details="auto"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-0">
              <v-col class="py-0">
                <v-checkbox v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      Saya menyetujui Kebijakan Privasi serta Syarat & Ketentuan
                      oleh Tim Assessment Pacific Marine School
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  :disabled="!checkbox"
                  large
                  elevation="0"
                  color="primary"
                  block
                  class="rounded-xl"
                  @click="submit()"
                >
                  Daftar
                </v-btn>
              </v-col>
              <!-- <v-col cols="12" align="center" class="py-0 mb-3">
                <div id="line">
                  <span class="color-disable font-weight-regular"
                    >Atau daftar dengan</span
                  >
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="" md="12">
                    <v-btn
                      large
                      elevation="0"
                      type="submit"
                      outlined
                      block
                      class="font-weight-medium rounded-xl"
                    >
                      <img src="@/assets/images/google.png" class="mr-1" />
                      Google
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
            <v-dialog
              transition="dialog-top-transition"
              max-width="600"
              v-model="dialog"
            >
              <v-card class="text-center">
                <v-card-text>
                  <div class="text-h4 pt-5">{{ this.nomer }}</div>
                  <p class="px-5 py-2 text-body-1">
                    Apakah nomor yang Anda masukkan sudah benar?
                  </p>
                  <v-card-actions class="justify-center">
                    <v-btn
                      outlined
                      rounded
                      color="primary"
                      @click="dialog = false"
                      :loading="submiting"
                      >Ubah</v-btn
                    >
                    <v-btn
                      dense
                      rounded
                      color="primary"
                      @click="otp()"
                      :loading="submitingSend"
                      >Benar</v-btn
                    >
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-show="verify" class="py-0 mx-auto" md="12">
      <v-row>
        <v-col class="white mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-0" justify="center">
              <v-col sm="6" lg="12" class="text-center">
                <v-icon class="text-h2 color-strike"
                  >mdi-message-processing-outline</v-icon
                >
                <div class="text-h5 color-strike">Masukan Kode Verifikasi</div>
                <p class="text-subtitle-1 color-disable">
                  Kode verifikasi telah dikirim melalui WA ke {{ this.nomer }}
                </p>
              </v-col>
            </v-row>
            <v-form>
              <v-container>
                <v-row>
                  <v-col id="kode" cols="12" sm="6" class="mx-auto py-0">
                    <!-- <v-text-field v-model="kodeOtp"></v-text-field> -->
                    <v-otp-input
                      :disabled="loading"
                      @finish="verifikasi()"
                      length="6"
                      v-model="kodeOtp"
                    ></v-otp-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <div class="text-subtitle-2 text-center color-disable mb-3">
              <span v-show="timerMessage"
                >Mohon tunggu dalam
                <b>{{ totalSeconds | minutesAndSeconds }} detik </b> untuk kirim
                ulang</span
              >
              <span v-show="resend">
                Tidak menerima kode ?
                <v-btn
                  plain
                  color="primary"
                  @click="sendOtp()"
                  class="pa-0 font-weight-bold"
                  >Kirim ulang
                </v-btn>
              </span>
            </div>
            <!-- <v-btn rounded block color="primary" class="" :loading="submitingOtp" @click="verifikasi()"
              >Verifikasi
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <v-snackbar top v-model="snackbar.state" color="primary" outlined>
        <!-- :timeout="timeout" -->
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "registration",
  data() {
    return {
      show: false,
      errors: [],
      regis: true,
      verify: false,
      valid: true,
      checkbox: false,
      nomer: "",
      password: "",
      kodeOtp: "",
      totalSeconds: 60,
      timer: {},
      interval: {},
      confirm_password: "",
      dialog: false,
      nomerRules: [(v) => !!v || "Nomer Whatsapp tidak boleh kosong"],
      pswRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      submiting: false,
      timerMessage: true,
      resend: false,

      snackbar: {
        state: false,
        text: "",
      },
      submitingSend: false,
      submitingOtp: false,
      response: null,
      loading: false,
    };
  },
  computed: {},
  filters: {
    minutesAndSeconds(value) {
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${seconds}`;
    },
  },
  methods: {
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        this.submiting = false;
        this.dialog = true;
        // setTimeout( () => {
        //   this.verify=true;
        //   this.regis=false;
        //   this.$router.push({ name: 'login'})
        // }
        // , 5000);
      } else {
        this.submiting = false;
      }
    },
    otp() {
      this.submitingSend = true;

      let access_key = process.env.VUE_APP_ACCESS_KEY;
      let provider = "whatsapp";
      let phone = this.nomer;
      let password = this.password;

      this.axios
        .post(
          `/users/v1/member/auth/register`,
          { provider, access_key, phone, password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // let res = response.data;
          // this.verify = true;
          // this.dialog = false;
          // this.regis = false;

          if (res.status == 200) {
            this.$router.push({ name: "login" });
          }
          // this.timer_start();
        })
        .catch((error) => {
          this.submitting = false;
          this.dialog = false;
          this.response = error.response.data.message;
          setTimeout(() => {
            this.response = null;
          }, 4000);

          // if (res.response) {
          //   this.snackbar.text = res.response.data.message;
          //   this.snackbar.state = true;
          // }
        })
        .finally(() => {
          this.submitingSend = false;
        });
    },
    timer_start() {
      this.timer = setInterval(() => {
        if (this.totalSeconds === 0) {
          this.totalSeconds = 60;
          clearInterval(this.timer);
          this.timerMessage = false;
          this.resend = true;
        }
        this.totalSeconds -= 1;
      }, 1000);
    },
    sendOtp() {
      this.timerMessage = true;
      this.resend = false;
      this.otp();
    },
    verifikasi() {
      this.submitingOtp = true;

      let access_key = process.env.VUE_APP_ACCESS_KEY;
      let phone = this.nomer;
      let otp = this.kodeOtp;

      this.axios
        .post(
          `/users/v1/member/auth/verify_otp`,
          { access_key, phone, otp },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.submitingOtp = false;
          // let res = response.data;
          if (res.status == 200) {
            this.$router.push({ name: "login" });
          }
        })
        .catch((res) => {
          this.submitting = false;
          this.verify = true;
          this.regis = false;
          this.kodeOtp = "";
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        })
        .finally(() => {
          this.submitingOtp = false;
        });
    },
  },
};
</script>

<style>
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
#kode .v-text-field input {
  font-size: 35px;
  text-align: center;
  letter-spacing: 10px;
}
.v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px;
}
</style>

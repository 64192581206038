<template>
  <div>
    <section id="detail-kelas" class="py-5">
      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height:60vh"
      >
        <v-col
          class="text-subtitle-1 text-center color-navy-soft"
          cols="12"
        >
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div v-show="content" class="col-md-10 mx-auto">
        <v-breadcrumbs large :items="items" class="pb-0 ps-0 pt-0"></v-breadcrumbs>
        <div class="row">
          <div class="col-md-12">
            <div>
              <v-card-text class="px-0">
              <p class="mb-0 fs-24 fs-xs-20 font-weight-bold">{{ this.detailClass.title }}</p>
              </v-card-text>
            </div>
          </div>
          <div class="col-md-8 d-none d-md-block">
            <v-card elevation="0">
              <v-card-text class="pt-0 px-0 color-black">
                <div class="d-none d-md-block">
                  <img :src="this.information.url" style="width: 100%;object-fit: cover;">
                  <v-tabs id="" v-model="tabs">
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize">Ulasan</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs" class="setting col-md-12">
                    <v-tab-item >
                      <div class="">
                                                          
                        <!-- <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3> -->
                        <!-- <div class="pa-2" v-html="this.detailClass.program_benefits" style="color:rgba(0, 0, 0, 0.87)"> -->
                        <vue-show-more-text
                          :text="detailClass.program_benefits"
                          :lines="10"
                          :more-text="more"
                          :less-text="less"
                          additional-content-css="font-size:0.875rem !important;"
                          additional-content-expanded-css="font-size:0.875rem !important;"
                          additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                          @click="change"
                        />
                          <!-- {{ this.detailClass.program_benefits }} -->
                        <!-- </div> -->

                        <!-- <h4 class="headline pa-2">Jenjang Usia</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          {{ this.program.age_level }}
                        </p>  -->                

                        <h3 class="pa-2">Kategori</h3>
                        <p class="pa-2 fs-fix">
                          {{ this.studies.title }}
                        </p> 

                        <!-- <h4 class="headline pa-2">Tujuan Pembelajaran</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          <ol type="number">
                            <li>Peserta mampu membangun persona diri sebagai content creator pada platform TikTok</li>
                            <li>Peserta mampu mengembangkan channel TikTok</li>
                            <li>Peserta mampu membuat strategi konten/video TikTok untuk memperoleh penghasilan</li>
                            <li>Peserta memahami teknik analisa Youtube</li>
                            <li>Peserta mampu membuat channel dan konten Youtube</li>
                            <li>Peserta mampu memonetisasi channel Youtube</li>
                            <li>Peserta mampu mengoptimalisasikan channel Youtube untuk memperoleh penghasilan</li> 
                          </ol>
                        </p>

                        <h4 class="headline pa-2">Bidang Kompetensi</h4>
                        <p class="pa-2 fs-18 text-capitalize">
                          {{ this.competence.title }}
                        </p> -->    

                        <h3 class="pa-2 mb-2">Fasilitator</h3>
                        <div class="row">
                          <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A'+ index">                        
                            <div class="col-md-12 ma-auto">                      
                              <img :src="item.picture.url" class="mx-2 text-center" style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                            </div>
                          </div>
                          <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B'+ index">                      
                            <p class="pa-2 mb-0 fs-fix" v-html="item.description"></p>
                          </div>
                          <!-- <div class="col-md-3">                      
                            <img src="https://storage.googleapis.com/ytc-cdn-prod/prakerja/WhatsApp%20Image%202021-07-08%20at%2018.25.27.jpeg" style="width: 150px;height: 150px;border-radius: 50%;object-fit: cover;">
                          </div>
                          <div class="col-md-9">                      
                            <h3 class="pa-2 fs-18">
                              {{ this.fasilitator.name }}
                            </h3>
                            <p class="pa-2 fs-18">
                              {{  this.fasilitator.description }}
                            </p>
                          </div> -->
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div v-if="detailClass.total_participants > 0" class="pt-5">
                        <p class="text-h5 mb-3">Peserta Bergabung <b style="color: #F05326;">{{detailClass.total_participants}}</b></p>
                      </div>
                      <div v-if="totalData === 0" class="col-md-12 text-center mt-5">
                        <img src="@/assets/images/empty-review.png" width="70">
                        <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                      </div>
                      <div v-else class="pt-5">
                        <!-- <div> -->
                          <!-- <v-chip v-if="detailClass.total_participants === 0" large class="font-weight-bold mb-5 text-capitalize" style="color: #3C4858;">
                            <v-icon left>
                              ri-user-line
                            </v-icon>
                            belum ada peserta yang mengikuti program ini
                          </v-chip>
                          <v-chip v-else class="font-weight-bold mb-5" large style="background: #FFE6DF;color: #F05326;">
                            <v-icon left color="primary">
                              ri-user-line
                            </v-icon>
                            {{detailClass.total_participants}} peserta yang mengikuti program ini
                          </v-chip> -->
                        <!-- </div> -->
                        <div class="rate col-md-12 text-center mb-3">
                          <div class="row">                            
                            <v-card class="px-1 py-4 rounded-lg" outlined elevation="0">
                              <h2 class="font-weight-bold display-2">{{this.rate.rating}}</h2>
                              <v-rating
                                v-model="this.rate.rating"
                                color="#FFCC18"
                                background-color="#E0E6ED"
                                empty-icon="$ratingFull"
                                half-increments
                                hover
                                small
                                readonly                          
                              ></v-rating>
                              <p class="text-muted fs-14 font-weight-bold mb-0 color-disable">{{ this.rate.count }} Ulasan</p> 
                            </v-card>
                            <v-card class="pa-2 col-md-4" elevation="0" style="width: 150px;">
                              <v-list-item-group>
                                  <!-- v-for="(item, index) in rate5" :key="index+'R'" -->
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate5"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate5}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate4"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate4}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate3"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate3}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate2"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate2}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate1"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate1}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-card>                       
                          </div>
                        </div>                        
                        <v-col md="12">
                          <p class="text-h6 mb-0">Apa Kata Mereka</p>
                          <v-list three-line>
                            <template>                              
                              <v-list-item v-for="(item, index) in this.listReview" :key="'A'+ index">
                                <v-list-item-avatar>
                                  <img src="@/assets/images/no-pict.png">
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title>{{ item.member.fullname}}</v-list-item-title>
                                  <div>
                                    <v-icon color="yellow" small v-for="index in item.star" :key="'B'+ index" >mdi-star</v-icon>                                  
                                    <!-- <v-icon color="yellow" small>mdi-star</v-icon>                                  
                                    <v-icon color="yellow" small>mdi-star</v-icon>                   -->                
                                  </div>
                                  <v-list-item-subtitle>{{ item.review }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <!-- <v-list-item-action>
                                  <v-list-item-action-text>2 menit yang lalu</v-list-item-action-text>
                                </v-list-item-action> -->
                              </v-list-item>
                            </template>
                          </v-list>
                          <v-btn
                            v-show="totalData > 2"
                            color="primary"
                            class="mx-auto text-center btn-beli"
                            text
                            @click="getReviewClass(-1)"
                          >
                            Tampilkan lebih banyak 
                          </v-btn>
                        </v-col>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-12 pa-0 d-lg-none d-md-none d-sm-block" style="background: #fff;min-height: 80vh;">
            <img :src="this.information.url" style="width: 100%">                  
                  <div class="button-beli pa-2 ps-5">
                    <span v-show="program.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(program.discount_price)}} </s></span>
                    <h2 class="color-state">Rp {{ toCurrency(program.selling_price)}}</h2>                    
                    <!-- <div class="pa-2 ma-auto text-center mb-3" style="width: 100%;">
                          <v-btn
                            color="primary"
                            rounded
                            class="mx-auto text-center white--text"
                            elevation="2"                          
                            block
                          >
                            Beli Kelas Online
                          </v-btn>
                    </div> -->      
                  </div>

                  <v-tabs v-model="tab" background-color="transparent" grow>
                    <v-tab class="text-capitalize">Deskripsi</v-tab>
                    <v-tab class="text-capitalize">Ulasan</v-tab>
                    <v-tab class="text-capitalize">Aktivitas</v-tab
                    >
                  </v-tabs>

                  <v-tabs-items v-model="tab" class="pa-2">
                    <v-tab-item >
                      <v-card elevation="0" class="bg-transparent pt-5">
                        <div class="">
                          <!-- <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3> -->
                          <!-- <div class="pa-2" v-html="this.detailClass.program_benefits" style="color:rgba(0, 0, 0, 0.87)">
                          </div> -->
                          <vue-show-more-text
                            :text="detailClass.program_benefits"
                            :lines="10"
                            :more-text="more"
                            :less-text="less"
                            additional-content-css="font-size:0.875rem !important;"
                            additional-content-expanded-css="font-size:0.875rem !important;"
                            additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                            @click="change"
                          />

                          <!-- <h4 class="headline pa-2">Jenjang Usia</h4>
                          <p class="pa-2 fs-18 text-capitalize">
                            {{ this.program.age_level }}
                          </p>  -->                

                          <h3 class="pa-2">Bidang Studi</h3>
                          <p class="pa-2 fs-fix">
                            {{ this.studies.title }}
                          </p> 

                          <!-- <h4 class="headline pa-2">Tujuan Pembelajaran</h4>
                          <p class="pa-2 fs-18 text-capitalize">
                            <ol type="number">
                              <li>Peserta mampu membangun persona diri sebagai content creator pada platform TikTok</li>
                              <li>Peserta mampu mengembangkan channel TikTok</li>
                              <li>Peserta mampu membuat strategi konten/video TikTok untuk memperoleh penghasilan</li>
                              <li>Peserta memahami teknik analisa Youtube</li>
                              <li>Peserta mampu membuat channel dan konten Youtube</li>
                              <li>Peserta mampu memonetisasi channel Youtube</li>
                              <li>Peserta mampu mengoptimalisasikan channel Youtube untuk memperoleh penghasilan</li> 
                            </ol>
                          </p>

                          <h4 class="headline pa-2">Bidang Kompetensi</h4>
                          <p class="pa-2 fs-18 text-capitalize">
                            {{ this.competence.title }}
                          </p> -->          
                          
                          <h3 class="pa-2 mb-2">Fasilitator</h3>
                          <div class="row">
                            <div v-for="(item, index) in this.detailClass.fasilitator" :key="'A'+ index">                        
                              <div class="col-md-12 ma-auto">                      
                                <img :src="item.picture.url" class="mx-2 text-center" style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;">
                              </div>
                            </div>
                            <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B'+ index">                      
                              <p class="pa-2 mb-0 fs-fix" v-html="item.description"></p>
                            </div>
                            <!-- <div class="col-md-3">                      
                              <img src="https://storage.googleapis.com/ytc-cdn-prod/prakerja/WhatsApp%20Image%202021-07-08%20at%2018.25.27.jpeg" style="width: 150px;height: 150px;border-radius: 50%;object-fit: cover;">
                            </div>
                            <div class="col-md-9">                      
                              <h3 class="pa-2 fs-18">
                                {{ this.fasilitator.name }}
                              </h3>
                              <p class="pa-2 fs-18">
                                {{  this.fasilitator.description }}
                              </p>
                            </div> -->
                          </div>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item >
                      <div class="container">
                        <div v-if="detailClass.total_participants > 0" class="pt-5">
                          <p class="fs-24 fs-xs-20 font-weight-bold mb-0">Peserta Bergabung <b style="color: #F05326;">{{detailClass.total_participants}}</b></p>
                        </div>
                        <div v-if="totalData === 0" class="col-md-12 text-center mt-5">
                          <img src="@/assets/images/empty-review.png" width="70">
                          <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                        </div>
                        <div v-else class="pt-5">
                          <div class="rate col-md-12 text-center mb-3">
                            <!-- <v-chip v-if="detailClass.total_participants === 0" class="font-weight-bold mb-5" style="color: #3C4858;">
                                <v-icon left>
                                  ri-user-line
                                </v-icon>
                                belum ada peserta yang redeem
                              </v-chip>
                              <v-chip v-else class="font-weight-bold mb-5" style="background: #FFE6DF;color: #F05326;">
                                <v-icon left color="primary">
                                  ri-user-line
                                </v-icon>
                                {{detailClass.total_participants}} peserta sudah redeem
                              </v-chip> -->
                            <div class="row">                            
                              <v-card class="px-1 col-12 py-4 rounded-lg" outlined elevation="0">
                                <h2 class="font-weight-bold display-2">{{this.rate.rating}}</h2>
                                <v-rating
                                  v-model="this.rate.rating"
                                  color="#FFCC18"
                                  background-color="#E0E6ED"
                                  empty-icon="$ratingFull"
                                  half-increments
                                  hover
                                  small
                                  readonly                          
                                ></v-rating>
                                <p class="text-muted fs-14 font-weight-bold mb-0 color-disable">{{ this.rate.count }} Ulasan</p> 
                              </v-card>
                              <v-card class="pa-2 col-12" elevation="0" style="width: 150px;">
                                <v-list-item-group>
                                    <!-- v-for="(item, index) in rate5" :key="index+'R'" -->
                                  <v-list-item
                                    class="border-0"
                                    style="min-height: auto!important;border:none!important;"
                                  >
                                    <v-list-item-icon class="mt-1 mb-0">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                                    </v-list-item-icon>

                                    <v-list-item-content class="py-0">
                                      <v-progress-linear
                                        v-model="persenRate5"                                      
                                        color="amber"
                                        height="8"
                                        rounded
                                      ></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{rate5}} ulasan </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item
                                    class="border-0"
                                    style="min-height: auto!important;border:none!important;"
                                  >
                                    <v-list-item-icon class="mt-1 mb-0">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                                    </v-list-item-icon>

                                    <v-list-item-content class="py-0">
                                      <v-progress-linear
                                        v-model="persenRate4"                                      
                                        color="amber"
                                        height="8"
                                        rounded
                                      ></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{rate4}} ulasan </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item
                                    class="border-0"
                                    style="min-height: auto!important;border:none!important;"
                                  >
                                    <v-list-item-icon class="mt-1 mb-0">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                                    </v-list-item-icon>

                                    <v-list-item-content class="py-0">
                                      <v-progress-linear
                                        v-model="persenRate3"                                      
                                        color="amber"
                                        height="8"
                                        rounded
                                      ></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{rate3}} ulasan </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item
                                    class="border-0"
                                    style="min-height: auto!important;border:none!important;"
                                  >
                                    <v-list-item-icon class="mt-1 mb-0">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                                    </v-list-item-icon>

                                    <v-list-item-content class="py-0">
                                      <v-progress-linear
                                        v-model="persenRate2"                                      
                                        color="amber"
                                        height="8"
                                        rounded
                                      ></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{rate2}} ulasan </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-list-item
                                    class="border-0"
                                    style="min-height: auto!important;border:none!important;"
                                  >
                                    <v-list-item-icon class="mt-1 mb-0">
                                      <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                                    </v-list-item-icon>

                                    <v-list-item-content class="py-0">
                                      <v-progress-linear
                                        v-model="persenRate1"                                      
                                        color="amber"
                                        height="8"
                                        rounded
                                      ></v-progress-linear>
                                    </v-list-item-content>
                                    <v-list-item-action class="my-0">
                                      <span class="color-disable">{{rate1}} ulasan </span>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-card>                       
                            </div>
                          </div>                        
                          <v-col md="12">
                            <p class="text-h6 pb-2">Ulasan Teman yang Lain</p>
                            <v-list three-line>
                              <template>                              
                                <v-list-item v-for="(item, index) in this.listReview" :key="'A'+ index">
                                  <v-list-item-avatar>
                                    <img src="@/assets/images/no-pict.png">
                                  </v-list-item-avatar>

                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.member.fullname}}</v-list-item-title>
                                    <div>
                                      <v-icon color="yellow" small v-for="index in item.star" :key="'B'+ index" >mdi-star</v-icon>                                  
                                      <!-- <v-icon color="yellow" small>mdi-star</v-icon>                                  
                                      <v-icon color="yellow" small>mdi-star</v-icon>                   -->                
                                    </div>
                                    <v-list-item-subtitle class="" style="display:block!important;">{{ item.review }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <!-- <v-list-item-action>
                                    <v-list-item-action-text>2 menit yang lalu</v-list-item-action-text>
                                  </v-list-item-action> -->
                                </v-list-item>
                              </template>
                            </v-list>
                            <v-btn
                              v-show="totalData > 2"
                              color="primary"
                              class="text-center"
                              block
                              text
                              @click="getReviewClass(-1)"
                              large
                            >
                              Tampilkan lebih banyak 
                            </v-btn>
                          </v-col>
                          
                        </div>
                      </div>
                    </v-tab-item>
                    <v-tab-item >
                      <div class="container">
                        <v-card elevation="0" class="bg-transparent">
                          <div>
                            <v-expansion-panels
                              class="custom-panels overflow-auto"
                              style="max-height: 64vh"
                            >
                              <v-expansion-panel
                                class="rounded-0"
                                v-for="(item, index) in this.detailClass.topic"
                                :key="index"
                              >
                                <v-expansion-panel-header class="px-4">
                                  <div>
                                    <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                                    <div class="text-body-2 color-navy-soft">
                                      {{ item.activities.length }} Aktivitas
                                    </div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="navy-soft pa-0">
                                  <v-list dense class="col-12 pa-0">
                                    <v-list-item-group>
                                      <v-divider></v-divider>
                                      <!-- <v-list-item
                                        v-for="(i, key) in 5"
                                        :key="i"
                                        class="grey lighten-5 d-block"
                                        color="primary"
                                        @click="
                                          pageType = i;
                                          showActivity = false;
                                        "
                                      > -->
                                      <v-list-item
                                        v-for="(act, i) in item.activities"
                                        :key="i"
                                        class="grey lighten-5 d-block"
                                        color="primary"                            
                                      >
                                        <!-- pageType = act; -->
                                        <v-list-item-content>
                                          <div class="d-flex my-1">
                                            <div style="width: 2rem">{{ i + 1 }}.</div>
                                            <div>
                                              <div
                                                class="
                                                  mb-1
                                                  font-weight-medium
                                                "
                                              >
                                                {{ act.title }}
                                              </div>
                                              <div
                                                class="color-navy-soft text-body-2 d-flex"
                                              >
                                                <template
                                                  v-if="act.type == 'menonton_video'"
                                                >
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-film-line</v-icon
                                                    >
                                                    Menonton Video
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template
                                                  v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'"
                                                >
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-survey-line</v-icon
                                                    >
                                                    Mengerjakan Soal
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template v-else-if="act.type == 'praktek_mandiri'">
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-file-download-line</v-icon
                                                    >
                                                    Praktek Mandiri
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-file-upload-line</v-icon
                                                    >
                                                    Evaluasi Praktek Mandiri
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template v-else-if="act.type == 'berdiskusi_daring'">
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-survey-line</v-icon
                                                    >
                                                    Berdiskusi Daring
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template v-else-if="act.type == 'evaluasi'">
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-edit-box-line</v-icon
                                                    >
                                                    Evaluasi
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                                <template v-else-if="act.type == 'membaca-e-book'">
                                                  <span class="d-flex mr-2">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-file-list-line</v-icon
                                                    >
                                                    Membaca E-book
                                                  </span>
                                                  <span class="d-flex">
                                                    <v-icon
                                                      class="navy-soft--text mr-1"
                                                      small
                                                      >ri-time-line</v-icon
                                                    >
                                                    <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                                    {{ dateTime(act.settings.estimated_duration)}}
                                                  </span>
                                                </template>
                                              </div>
                                            </div>
                                          </div>
                                        </v-list-item-content>
                                        <v-divider
                                          style="
                                            position: absolute;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                          "
                                        ></v-divider>
                                      </v-list-item>
                                    </v-list-item-group>
                                  </v-list>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <div class="button-beli pa-2">
                              <!-- <span class="fs-14 color-strike"><s>Rp 750,000 </s></span>
                              <h2 class="color-state mb-3">Rp 499,000</h2> -->
                              <v-btn
                                color="primary"
                                rounded
                                class="mx-auto text-center btn-beli"
                                block
                                elevation="2"
                                @click="dialog=true"
                              >
                                Beli Kelas Online
                              </v-btn>
                            </div>
                          </div> 
                        </v-card>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
          </div>
          <div class="col-md-4">
            <v-card class="d-none d-md-block">
              <v-card-text class="pb-0">
                <h2 class="mb-3 color-sale">Aktivitas</h2>
              </v-card-text>
              <div>
                <v-expansion-panels
                  class="custom-panels overflow-auto"
                  style="max-height: 64vh"
                >
                  <v-expansion-panel
                    class="rounded-0"
                    v-for="(item, index) in this.detailClass.topic"
                    :key="index"
                  >
                    <v-expansion-panel-header class="px-4">
                      <div>
                        <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                        <div class="text-body-2 color-navy-soft">
                          {{ item.activities.length }} Aktivitas
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="navy-soft pa-0">
                      <v-list dense class="col-12 pa-0">
                        <v-list-item-group>
                          <v-divider></v-divider>
                          <!-- <v-list-item
                            v-for="(i, key) in 5"
                            :key="i"
                            class="grey lighten-5 d-block"
                            color="primary"
                            @click="
                              pageType = i;
                              showActivity = false;
                            "
                          > -->
                          <v-list-item
                            v-for="(act, i) in item.activities"
                            :key="i"
                            class="grey lighten-5 d-block"
                            color="primary"                            
                          >
                            <!-- pageType = act; -->
                            <v-list-item-content>
                              <div class="d-flex my-1">
                                <div style="width: 2rem">{{ i + 1 }}.</div>
                                <div>
                                  <div
                                    class="
                                      mb-1
                                      font-weight-medium
                                    "
                                  >
                                    {{ act.title }}
                                  </div>
                                  <div
                                    class="color-navy-soft text-body-2 d-flex"
                                  >
                                    <template
                                      v-if="act.type == 'menonton_video'"
                                    >
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-film-line</v-icon
                                        >
                                        Menonton Video
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template
                                      v-else-if="act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'"
                                    >
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-survey-line</v-icon
                                        >
                                        Mengerjakan Soal
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-download-line</v-icon
                                        >
                                        Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-upload-line</v-icon
                                        >
                                        Evaluasi Praktek Mandiri
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'berdiskusi_daring'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-survey-line</v-icon
                                        >
                                        Berdiskusi Daring
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'evaluasi'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-edit-box-line</v-icon
                                        >
                                        Evaluasi
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                    <template v-else-if="act.type == 'membaca-e-book'">
                                      <span class="d-flex mr-2">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-file-list-line</v-icon
                                        >
                                        Membaca E-book
                                      </span>
                                      <span class="d-flex">
                                        <v-icon
                                          class="navy-soft--text mr-1"
                                          small
                                          >ri-time-line</v-icon
                                        >
                                        <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                        {{ dateTime(act.settings.estimated_duration)}}
                                      </span>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </v-list-item-content>
                            <v-divider
                              style="
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                              "
                            ></v-divider>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div class="button-beli pa-2">
                  <span v-show="program.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(program.discount_price)}} </s></span>
                  <h2 class="color-state mb-3">Rp {{ toCurrency(program.selling_price)}}</h2>
                  <v-btn
                    color="primary"
                    rounded
                    class="mx-auto text-center btn-beli"
                    block
                    elevation="2"
                    @click="dialog=true"
                  >
                    Beli Kelas Online
                  </v-btn>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </section>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="fs-22 fs-xs-18 font-weight-bold">Pilih Platform Pembelian</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              class="ml-auto"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="pb-0 pt-2">
          <div class="col-md-8 text-center mx-auto py-3">
            <div v-for="(item, index) in program.selling_link" :key="'A'+ index">              
              <v-btn depressed class="rounded-xl mb-2 white--text" color="primary" :href="item.url" target="_blank" block>{{ item.title }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import vueShowMoreText from 'vue-show-more-text'

export default {
  name: "detail-kelas",
  components: {
    vueShowMoreText
  },
  data() {
    return {
      offset:true,
      a:5,
      rate:{},
      loadContent:true,
      content:false,
      tabs:null,
      tab:null,
      items: [
        {
          text: 'Beranda',
          disabled:false,
          color:'primary',
          href: '/',
        },
        {
          text: 'Kelas',
          disabled: true,
          href: '#',
        }
      ],
      admins: [
        ['Membuat Fake Project','ri-film-line','Video','ri-time-line','00:08:00'],
        ['Quiz 1','ri-survey-line','Latihan Soal'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      detailClass: {},
      program:{},
      studies:{},
      competence:{},
      fasilitator:{},
      act: {},
      id_act: "",
      id_topic: "",
      information:{},
      listReview:{},
      // program:{},

      more:'Lihat Selengkapnya',
      less:'Sembunyikan',
      dialog:false,
      totalData:null,


      //inisialisasi rate
      rate5:null,
      rate4:null,
      rate3:null,
      rate2:null,
      rate1:null,
      persenRate1:null,
      persenRate2:null,
      persenRate3:null,
      persenRate4:null,
      persenRate5:null
    }
  },
  created() {
    this.getDetailClass();
    this.getReviewClass(2);
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  computed:{
    
  },
  methods: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'IDR',
          // minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    dateTime(value) {
      var minut=value*60;
      var hours = `${Math.floor(minut / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(minut / 60) % 60}`.padStart(2, 0);
      var seconds = `${minut % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },
    change(showAll) {
      console.log(showAll)
    },
    getDetailClass() {
      this.axios
        .get(`/users/v1/public/program/detail/${this.$route.params.id}`)
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          this.detailClass = res;
          this.information = res.program_information.cover;
          // this.program = res.program_information;
          this.program = res.program_information;
          this.studies= res.learning_targets.studies;
          this.competence= res.learning_targets.competence;
          this.fasilitator= res.fasilitator;
        });
    },
    getReviewClass(value) {
      this.axios
        .get(`/program_review/v1/list?program_id=${this.$route.params.id}&sort=created_at&sort_type=desc&is_publish=1&limit=${value}`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
        // {
        //   headers: { "Guest-key": "Bhg67YYts10" },
        // })
        .then((response) => {
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          this.listReview = res.list;
          this.rate = res.rating;
          this.totalData=res.total_data;
          this.rate5=res.stars[0].star5;
          this.rate4=res.stars[0].star4;
          this.rate3=res.stars[0].star3;
          this.rate2=res.stars[0].star2;
          this.rate1=res.stars[0].star1;
            // console.log(this.rate5)
          this.persenRate5=parseInt( Math.round( ( this.rate5/this.rate.count ) * 100 ) );
          this.persenRate4=parseInt( Math.round( ( this.rate4/this.rate.count ) * 100 ) );
          this.persenRate3=parseInt( Math.round( ( this.rate3/this.rate.count ) * 100 ) );
          this.persenRate2=parseInt( Math.round( ( this.rate2/this.rate.count ) * 100 ) );
          this.persenRate1=parseInt( Math.round( ( this.rate1/this.rate.count ) * 100 ) );
        });
    },
  },
};
</script>

<style>

.list-topik{
  max-height: 300px;
  overflow: auto;
}
#detail-kelas .v-list-group__items{
  background: #F9FAFC;
}
#detail-kelas .v-list-item{
  border-bottom: 1px solid rgba(0,0,0,.12);
}
#detail-kelas .v-tab--active.v-tab:not(:focus):before {
  opacity: 0;
}
@media (max-width: 425px){
  #detail-kelas .v-list-item {
    /*border-bottom: 0px solid rgba(0,0,0,.12);*/
  }
}
</style>
<template>
  <div>
    <section id="banner">
      <div class="col-md-10 mx-auto pa-0 pt-5">
        
      <v-carousel cycle hide-delimiter-background touchless :show-arrows="false" class="box-slider">
          <v-carousel-item v-for="i in 4" :key="i">
            <img 
              :src="require(`@/assets/images/banner/PMS - Header Banner ${i}.png`)"
              class="img-banner"
            />
          </v-carousel-item>
      </v-carousel>
      </div>
    </section>

    <section id="kategori" class="py-5">
      <div v-show="total > 0" class="col-md-10 mx-auto">
        <div class="mb-sm-3 d-flex">
          <p class="my-auto fs-22 fs-xs-18 font-weight-bold text-capitalize">Kelas Populer</p>
          <div v-show="total === 4" class="button-selengkapnya ms-auto text-end">
            <v-btn class="px-1 font-weight-bold text-decoration-line" text color="primary" :href="$router.resolve({name: 'studi',query: { is_free: true }}).href">
              <span class="fs-16 fs-xs-14">Lihat Semua</span>
            </v-btn>
          </div>
        </div>
        <div class="row box-deks">
          <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
            <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
            </v-skeleton-loader>
          </div>
          <div
            v-else
            v-for="(item, index) in listKhusus" :key="index+'B'"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto transparent" elevation="0">
              <router-link
                class=""
                :to="{ name: 'detail-kelas-user', params: { id: item.id } }"
                @click.stop
              >
                <img
                  :src="item.program_information.cover.url"
                  class="img-program rounded-lg"
                />

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="black--text font-weight-medium text-dots-2 fs-16 fs-xs-14"
                      >{{item.title}}</span>
                    </template>
                    <span class="text-subtitle-2">{{item.title}}</span>
                  </v-tooltip>
                  <span v-if="item.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(item.program_information.discount_price)}}</s></span>
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <h3 class="color-state">Rp {{ toCurrency(item.program_information.selling_price)}}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn
                  color="primary"
                  rounded
                  outlined
                  class="px-3 fs-14 font-weight-medium btn-beli"
                  
                  @click="getDialog(item.id)"
                >
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
        <div class="row box-program">
          <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
            <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
            </v-skeleton-loader>
          </div>
          <div
            v-else
            v-for="(item, index) in listKhususLimit" :key="index+'B'"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto transparent" elevation="0">
              <router-link
                class=""
                :to="{ name: 'detail-kelas-user', params: { id: item.id } }"
                @click.stop
              >
                <img
                  :src="item.program_information.cover.url"
                  class="img-program rounded-lg"
                />

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="black--text font-weight-bold text-dots-2 fs-18"
                      >{{item.title}}</span>
                    </template>
                    <span class="text-subtitle-2">{{item.title}}</span>
                  </v-tooltip>
                      <!-- <h3 class="text-dots-2 text-capitalize black--text">{{item.title}}</h3> -->
                  <span v-if="item.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(item.program_information.discount_price)}}</s></span>                  
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <!-- <p class="color-disable mb-0 fs-14 text-dots-2" v-text="item.program_benefits"></p>                   -->
                  <h3 class="color-state">Rp {{ toCurrency(item.program_information.selling_price)}}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn
                  color="primary"
                  rounded
                  outlined
                  class="btn-beli"
                  
                  @click="getDialog(item.id)"
                >
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
      <div class="pt-6 pt-md-3 col-md-10 mx-auto" v-for="(item, index) in list" :key="index">
        <div class="mb-3 d-flex">
          <p class="my-auto fs-22 fs-xs-18 font-weight-bold text-capitalize">{{ item.title }}</p>
          <div v-show="item.programs.length === 4" class="button-selengkapnya ms-auto text-end">
            <v-btn class="px-1 font-weight-bold text-decoration-line" text color="primary" :href="$router.resolve({name: 'studi',query: { studi: item.id, is_free: false }}).href">
              <span class="fs-16 fs-xs-14">Lihat Semua</span>
            </v-btn>
          </div>
        </div>
        <div class="row box-program-limit">
          <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
            <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
            </v-skeleton-loader>
          </div>
          <div
            v-else
            v-for="(program, index2) in item.programs"
            :key="'A'+ index2"
            class="col-xl-3 col-md-3 col-sm-6 col-6"
          >
            <v-card class="mx-auto transparent" elevation="0">             
              <router-link
                class=""
                :to="{ name: 'detail-kelas-user', params: { id: program.id } }"
                @click.stop
              >
                <img
                  :src="program.program_information.cover.url"
                  class="img-program rounded-lg"
                />

                <v-card-subtitle class="pa-0 box-content-title">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="black--text font-weight-bold text-dots-2 fs-18"
                      >{{program.title}}</span>
                    </template>
                    <span class="text-subtitle-2">{{program.title}}</span>
                  </v-tooltip>
                      <!-- <h3 class="text-dots-2 text-capitalize black--text">{{program.title}}</h3> -->
                  <span v-if="program.program_information.discount_price != 0" class="fs-14 color-strike"><s>Rp {{ toCurrency(program.program_information.discount_price)}}</s></span>                  
                </v-card-subtitle>

                <v-card-text class="pa-0">
                  <!-- <p class="color-disable mb-0 fs-14 text-dots-2" v-text="program.program_benefits"></p>                   -->
                  <h3 class="color-state">Rp {{ toCurrency(program.program_information.selling_price)}}</h3>
                </v-card-text>
              </router-link>
              <v-card-actions class="pa-0 pt-2">
                <v-btn
                  color="primary"
                  rounded
                  outlined
                  class="btn-beli"
                  
                  @click="getDialog(program.id)"
                >
                  Beli Kelas Online
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </section>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title class="fs-22 fs-xs-18 font-weight-bold">Pilih Platform Pembelian</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              class="ml-auto"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- <v-card-title class="text-h5">
          Pilih Pembelian Program
        </v-card-title> -->

        <v-divider></v-divider>

        <v-card-text class="pb-0 pt-2">
          <div class="col-md-8 text-center mx-auto py-3">
            <div v-for="(item, index) in link" :key="'A'+ index">
              <v-btn depressed class="rounded-xl mb-2 white--text" color="primary" :href="item.url" target="_blank" block>{{ item.title }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import resources from "@/store/resources"

export default {
  name: "public-beranda",
  components: {   },
  data: () => ({
    model:null,
    resources: [],
    list:[],
    listKhusus:[],
    listKhususLimit:[],
    offset:true,
    dialog:false,
    loading:false,
    total:null,
    link:[]
  }),
  created() {
    this.resources = resources
  },
  mounted() {
    this.getList();
    // this.getListKhusus();
    // this.getListKhususLimit();
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    getList(){
      this.loading=true;
      this.axios
        .get("users/v1/public/program/study_group?limit=10&ids=643661ee1639c35dc665287c",{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }
          })
        .then((response) => {
          this.loading=false;
          this.content=true;
          this.loadContent=false;
          let res = response.data.data;
          // if (response.status == 200) {
            this.submitting = false;
            this.list = res;
            // console.log('list',response)
          // }
        });
    },
    getListKhusus(){
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=1&limit=4&sort=_id&dir=desc&is_free=true`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.listKhusus = res.list;
          this.total= res.total
        });
    },
    getListKhususLimit(){
      this.axios
        .get(`users/v1/public/program/list?status=publish&page=1&limit=2&sort=_id&dir=desc&is_free=true`,{
            headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
          })
          
        .then((response) => {
          let res = response.data.data;
          this.listKhususLimit = res.list;
          this.total= res.total
        });
    },
    getDialog(id){
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          // let res = response.data.data;
          this.link= response.data.data.program_information.selling_link;
          this.dialog=true;
        });
    },
    login(){
      this.$router.push("/auth/login");
    },
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('de-DE', {
          // style: 'currency',
          // currency: 'IDR',
          // minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
    
  },
};
</script>

<style scoped>

.box-slider{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;
}

#banner {
  width: 100%;
}
#banner .v-carousel__controls>.v-item-group{
  position: absolute;
  /*left: 11%!important;*/
}
#banner .v-carousel__controls__item{
  margin: 0;
}
#banner .v-image{
  /*height: 360px!important;*/
  height: auto!important;

}
#banner .v-carousel__controls{
  bottom:5%!important;

}
.img-banner{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;

  /*object-fit: contain;*/
}
.img-program {
  width: 100%;
  height: 200px;
  object-fit: fill;
  border-radius: 8px 8px 0 0;
}
.img-kategori {
  width: 100%;
}
/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px){
  .img-program {
    height: 125px;
    object-fit: fill;
  }
  .box-slider{
    height: auto!important;
  }
  #banner .v-btn--icon.v-size--small{
    height: 15px;
    width: 15px;
  }
  #banner .v-btn__content i{
    font-size: 12px!important;
  }
  #banner .v-carousel__controls{
    bottom:-10px;
  }
  .v-carousel__item{height: auto!important;}    
  .img-banner{height: auto!important;}
  .bg-cta-login {
    background-position: right!important;
    height: auto!important;
    width: 100%;
  }
  #cta-login .headline{
    font-size: 16px!important;
  }
  .btn-beli{
    font-size: 12px!important;
  }
}
@media (max-width: 375px){
}
</style>
<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <div class="font-weight-bold mb-3">{{ act.title }}</div>
    <v-card elevation="0" class="col-md-12">
      <div class="pa-4">
        <div class="pa-4">
          <p
            class="text-body-1 text-justify mb-4"
            v-html="this.act.theory.description"
          ></p>
          <template v-if="showMeetingLink">
            <template v-if="meetingSchedule.is_day">
              <div
                v-for="(item, index) in meetingSchedule.schedules"
                :key="index"
              >
                <p>
                  Hari {{ day(item.day) }},
                  <template v-for="(time, index2) in item.times">
                    <span
                      v-if="
                        jamSekarang <= time.end_time &&
                        jamSekarang >= time.start_time
                      "
                      :key="index2"
                    >
                      pukul {{ time.start_time }} -
                      {{ time.end_time }} WIB<br />
                    </span>
                  </template>
                </p>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(item, index) in meetingSchedule.schedules"
                :key="index"
              >
                <p>
                  Tanggal {{ date(item.date) }} ,

                  <template v-for="(time, index2) in item.times">
                    <span
                      v-if="
                        jamSekarang <= time.end_time &&
                        jamSekarang >= time.start_time
                      "
                      :key="index2"
                    >
                      pukul {{ time.start_time }} -
                      {{ time.end_time }} WIB<br />
                    </span>
                  </template>
                </p>
              </div>
            </template>

            <p class="pt-1 mb-1">Link Meeting :</p>

            <v-btn
              small
              color="primary"
              dark
              depressed
              class="white px-2 rounded-lg"
              :href="this.act.theory.meeting.link"
              target="_blank"
              @click="handleClickMeetingLink"
            >
              Klik Disini
            </v-btn>

            <div class="mt-3">
              <div v-for="(item, index) in metaData" :key="'A' + index">
                <p>{{ item.title }} : {{ item.content }}</p>
              </div>
            </div>
          </template>
          <v-card elevation="0" class="py-3 rounded-lg">
            <iframe
              v-if="this.act.theory.link_embed.url != ''"
              :src="this.act.theory.link_embed.url"
              style="height: 500px; border-style: hidden"
            ></iframe>
          </v-card>

          <template v-if="act?.theory?.meeting?.is_clicked">
            <div class="pt-3">
              Segera klik "Selesaikan Aktivitas" setelah jadwal live webinarmu
              berakhir!:
            </div>
          </template>

          <template v-if="isFinishSchedule">
            <v-btn
              depressed
              class="px-2 rounded-lg"
              color="primary"
              :loading="btn_loading"
              @click="getUnlock()"
            >
              Selesaikan Aktivitas
            </v-btn>
          </template>
        </div>
      </div>
    </v-card>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    act: Object,
    detail_class: Object,
    id_topic: String,
    id_act: String,
  },
  data() {
    return {
      btn_loading: false,
      is_clicked_btn: false,
    };
  },
  computed: {
    meetingSchedule() {
      if (!this.act.theory) return [];
      const is_day = this.act.theory.meeting.is_day;
      if (is_day) return { is_day, schedules: this.act.theory.meeting.days };
      return { is_day, schedules: this.act.theory.meeting.dates };
    },
    timeIsIt() {
      const theDate = new Date();
      return {
        date: theDate.getDate().toString().padStart(2, 0),
        month: (theDate.getMonth() + 1).toString().padStart(2, 0),
        year: theDate.getFullYear(),
        hour: theDate.getHours().toString().padStart(2, 0),
        minute: theDate.getMinutes().toString().padStart(2, 0),
      };
    },
    jamSekarang() {
      return `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
    },
    tanggalSekarang() {
      return `${this.timeIsIt.year}-${this.timeIsIt.month}-${this.timeIsIt.date}`;
    },
    showMeetingLink() {
      const config = this.act?.theory?.meeting;
      // nek disembunyikan berarti muncul nek pas sesuai jadwal
      if (config && config.is_must_participate) {
        const theDate = new Date();
        const days = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];
        const tanggal = this.tanggalSekarang;
        // hs = hari sekarang
        const hs = theDate.getDay();
        // jbs = jam berapa sekarang
        const jbs = `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
        switch (config.is_day) {
          case false:
            return config.dates.some((val) => {
              if (tanggal == val.date.slice(0, 10)) {
                return val.times.some((val2) => {
                  console.log(jbs, val2.start_time, val2.end_time);
                  return jbs >= val2.start_time && jbs <= val2.end_time;
                });
              }
            });
          case true:
            return config.days.reduce((car, cur) => {
              const dayIndex = days.findIndex((v) => v == cur.day);
              if (dayIndex == hs)
                return (
                  car ||
                  cur.times.reduce((car2, cur2) => {
                    return (
                      car2 || (jbs >= cur2.start_time && jbs <= cur2.end_time)
                    );
                  }, false)
                );
              return car;
            }, false);
          default:
            return false;
        }
      }
      // nek gak disembunyikanberarti muncul terus
      // nek link e kosong berarti ra muncul
      return true;
    },
    isFinishSchedule() {
      if (!this.finishSchedule) return true;
      const theDate = new Date();
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      let in_time = false;
      const daySchedule = this.finishSchedule.days || [];
      const dateSchedule = this.finishSchedule.dates || [];
      const tanggal = this.tanggalSekarang;
      const jbs = `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;

      switch (this.finishSchedule.is_day) {
        case "1":
          in_time = daySchedule.some((val) => {
            const dayIndex = days.findIndex((v) => v == val.day);
            return (
              theDate.getDay() == dayIndex &&
              jbs >= val.start_time &&
              jbs <= val.end_time
            );
          });
          break;
        case "2":
          in_time = dateSchedule.some((val) => {
            return (
              val.date == tanggal &&
              jbs >= val.start_time &&
              jbs <= val.end_time
            );
          });
          break;
        default:
          break;
      }

      return (
        in_time &&
        this.act?.theory?.meeting?.is_clicked &&
        !this.act.is_complete &&
        !this.act.end_test_at
      );
    },
    finishSchedule() {
      const finishSchedule = this.act.theory.meta_data.find(
        (v) => v.title == "finish_activity"
      );
      if (finishSchedule && finishSchedule.content)
        return JSON.parse(finishSchedule.content);
      return {};
    },
    metaData() {
      return this.act.theory.meta_data.filter(
        (v) => v.title != "finish_activity"
      );
    },
  },
  methods: {
    day(a) {
      const day = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const index = day.findIndex((v) => v == a);
      return hari[index];
    },
    date(a) {
      return a ? moment(a).locale("en-gb").format("L") : null;
    },
    changeIsClickedBtn() {
      this.is_clicked_btn = true;
    },
    handleClickMeetingLink() {
      this.btn_loading = true;
      const data = {
        member_redeem_id: this.detail_class.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .put(`/users/v1/member/activity/update_meeting_is_clicked`, data)
        .then(() => {
          this.btn_loading = false;
          this.$emit("getActFalse", this.id_topic, this.id_act);
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    getUnlock() {
      this.btn_loading = true;
      const data = {
        program_id: this.$route.params.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.btn_loading = false;
          this.$emit("getActFalse", this.id_topic, this.id_act);
          this.$emit("getDetailClass");
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
  },
};
</script>

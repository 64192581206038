<template>
  <v-card elevation="0" class="transparent px-2">
    <vue-html2pdf ref="certifPeserta" :enable-download="true" :preview-modal="false" :show-layout="true" :pdf-quality="2"
      :filename="certificateName" pdf-orientation="landscape" :manual-pagination="true" pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)">
      <section slot="pdf-content">
        <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/bg-new-certif.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
          <div class="style1" v-if="Object.keys(data).length > 0">
            <div style="text-align: center;margin-bottom: 15px;">
              <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
            </div>
            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 47px;
                        text-align: center;
                        letter-spacing: 0.015em;
                        margin-top: 10px;
                      ">
              Sertifikat Penyelesaian
            </div>
            <div style="
                        font-family: Roboto;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        margin-top: 4px;
                      ">
              Diberikan atas keberhasilan mengikuti dan lulus dari Kelas <br>
              {{ data.program_name }} <br>Kepada
            </div>

            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 900;
                        font-size: 32px;
                        line-height: auto;
                        text-align: center;
                        margin-top: 20px;
                        padding-bottom 5px;
                      ">
              {{ data.user_name }}
            </div>
            <hr style="width: 35%; border: 1px solid rgba(0, 0, 0, 0.2); margin: auto;">
            <div style="
                      font-family: Roboto;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 14px;
                      text-align: center;
                      margin-top: 10px;
                    ">
              <!-- <div style="margin-top: 5px">Dibawah bimbingan tenaga pelatih : {{ data.fasiltator[0].name }}</div> -->
              <div style="margin-top: 5px">atas keberhasilan menyelesaikan program selama 15 jam</div>
            </div>
            <div v-if="data.feedback_score >= 80"
              style="font-size: 16px; font-family: Roboto; text-align: center; margin-top: 10px;">
              <span style="font-size: 18px; font-weight: 900; padding-right: 8px">LULUS</span>
              <span style="padding-right: 8px">dengan predikat</span>
              <span style="font-size: 18px; font-weight: 900;">"SANGAT BAIK"</span>
            </div>
            <template v-if="false">
              <div v-if="data.feedback_score >= 80"
                style="font-family: Roboto; font-weight: normal; margin-top: 5px; font-size: 14px; text-align: center;">
                dengan nilai akhir: {{ data.feedback_score }}
              </div>
            </template>
            <div style="width:80%;display: flex;margin-top: 45px;margin-left: auto;margin-right: auto;">
              <div style="width: 33%; margin: auto">
                <div style="
                          font-family: Roboto;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 14px;
                          text-align: center;
                        ">
                  <vue-qrcode :value="data.certificate_url"
                    :options="{ color: { dark: '#4B4B4B', }, width: 100 }"></vue-qrcode>
                  <div>{{ data.certificate_number }}</div>
                </div>
              </div>
              <div style="width: 33%;margin:0 5%;margin-right: 0;">
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 16px;
                            text-align: center;
                            margin-bottom: 5px;
                            /*margin-top: 30px;*/
                          ">
                  Yogyakarta,
                  {{ currentDateTime(data.end_test_at) }}
                </div>
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: center;
                            /*margin-top: 29px;*/
                          ">
                  Director,
                </div>
                <div style="margin-top: 10px; text-align: center">
                  <img width="50%" src="@/assets/images/ttd-ytc.png" alt="" srcset="" />
                </div>
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 14px;
                            text-align: center;
                            margin-top: 6px;
                          ">
                  M. Animna Husna, S.I.A.
                </div>
              </div>
              <div style="width: 33%; margin: auto auto auto 3.2rem;">
                <div style="
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                          ">
                  <div v-if="this.data.is_prakerja === true">
                    <p style="text-align: left;margin-bottom: 10px;">Diverifikasi oleh : </p>
                    <img src="@/assets/images/prakerja-new.png" alt="" srcset="" style="width:60%;text-align: left;" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          v-if="data.program_name == 'Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Merencanakan Strategi Pemasangan Iklan di Media Sosial Untuk Menjadi Manajer Periklanan.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menyusun Surat Perjanjian Bahasa Inggris bagi Calon Staf Legal'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Surat Perjanjian Bahasa Inggris bagi Calon Staf Legal.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'Layanan Pelanggan Berbasis Digital'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Layanan Pelanggan Berbasis Digital.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'Pelayanan Prima untuk Customer Service Online'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Prima untuk Customer Service Online.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'Mengelola Dokumen untuk Staf Perkantoran'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Dokumen untuk Staf Perkantoran.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'Dasar Keselamatan dan Kesehatan Kerja di Tempat Kerja'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/[11_07_2023] Dasar Keselamatan dan Kesehatan Kerja di Tempat Kerja (2).png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menghitung Gaji Bersih di Excel untuk HR'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Menghitung Gaji Bersih di Excel untuk HR.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Komunikasi Efektif dan Negosiasi Bagi Staf Penjualan'" class="pdf-item"
          :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Komunikasi Efektif dan Negosiasi Bagi Staf Penjualan.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'Menghitung Tunjangan di Excel untuk HR'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menghitung Tunjangan di Excel untuk HR.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menyusun Surat Bisnis dalam Bahasa Inggris Bagi Calon Sekretaris'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Surat Bisnis dalam Bahasa Inggris Bagi Calon Sekretaris.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Menyusun Laporan Keuangan dengan Microsoft Excel untuk Menjadi Staf Akuntan'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Laporan Keuangan dengan Microsoft Excel untuk Menjadi Staf Akuntan.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengolah Data Penjualan dengan Microsoft Excel untuk Menjadi Admin Input Data.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menggunakan Bahasa Inggris Bagi Calon Tenaga Perkantoran Umum'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menggunakan Bahasa Inggris Bagi Calon Tenaga Perkantoran Umum.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menggunakan Bahasa Inggris Bagi Calon Sekretaris'" class="pdf-item"
          :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menggunakan Bahasa Inggris Bagi Calon Sekretaris.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name.toLowerCase() == 'Pengelolaan Akuntansi Dan Perpajakan Bagi Akuntan Untuk UMKM'.toLowerCase()"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Pengelolaan Akuntansi dan Perpajakan bagi Akuntan untuk UMKM.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengembangkan Konten di Media Sosial Untuk Menjadi Spesialis Pemasaran Digital.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Mengelola Gaji Karyawan dengan Microsoft Excel Untuk Menjadi Staf Personalia'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Gaji Karyawan dengan Microsoft Excel Untuk Menjadi Staf Personalia.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Melakukan Riset Kata Kunci dan Optimasi SEO Website Untuk Menjadi Manajer Pemasaran.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Melakukan Komunikasi Bahasa Inggris Melalui Panggilan Telepon Bagi Calon Petugas Informasi Pusat Kontak.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Kinerja Karyawan Untuk Menjadi Manajer Sumber Daya Manusia.jpg')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section
          v-else-if="data.program_name == 'Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Merumuskan Kebijakan Organisasi dan Strategi Pengelolaan SDM Untuk Menjadi Manajer Sumber Daya Manusia.jpg')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Strategi Pemasaran Untuk Spesialis Pengembangan Bisnis'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Strategi Pemasaran Untuk Spesialis Pengembangan Bisnis.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Merancang Desain Konten Untuk Desainer Grafis'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Merancang Desain Konten Untuk Desainer Grafis.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Desain Logo dan Konten Untuk Desainer Grafis'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/desain-logo-dan-konten-untuk-desainer-grafis.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Kepuasan Pelanggan untuk Kasir'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Kepuasan Pelanggan untuk Kasir.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Microsoft Excel untuk Staf Admin'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/microsoft-excel-untuk-staf-admin.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Dasar Keselamatan dan Kesehatan Kerja di Tempat Kerja'" class="pdf-item"
          :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Dasar Keselamatan Dan Kesehatan Kerja Di Tempat Kerja.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Bahasa Inggris Untuk Karyawan'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bahasa-inggris-untuk-karyawan.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Bahasa Inggris Untuk Legal'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bahasa-inggris-untuk-legal-belakang.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Bahasa Inggris untuk Administrasi dan Legalitas Perkantoran'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bahasa-inggris-untuk-adminsitrasi-dan-legalitas-perkantoran-belakang.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Komunikasi Pelanggan Untuk Kasir'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Komunikasi Pelanggan Untuk Kasir-2.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Komunikasi Publik Untuk Customer Service'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Komunikasi Publik Untuk Customer Service.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Pelayanan Prima Untuk Staf Toko'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Prima Untuk Staf Toko.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Mengelola Gaji Karyawan dengan Microsoft Excel'" class="pdf-item"
          :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Gaji Karyawan Dengan Microsoft Excel.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Menyusun Surat Bisnis dalam Bahasa Inggris bagi Calon Sekretaris'"
          class="pdf-item" :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/Menyusun Surat Bisnins Dalam Bahasa Inggris Bagi Calon Sekretaris.png')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `790px`,
            color: `#4B4B4B`,
          }">
        </section>
        <section v-else-if="data.program_name == 'Kepuasan Pelanggan untuk Staf Toko'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Kepuasan Pelanggan untuk Staf Toko.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name == 'Pelayanan Pelanggan untuk Customer Service'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Pelayanan Pelanggan untuk Customer Service .png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else-if="data.program_name.toLowerCase() == 'mengelola dokumen untuk staf perkantoran umum'" class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/Mengelola Dokumen untuk Staf Perkantoran.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
        </section>
        <section v-else class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/bg-new-certif.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        }">
          <div v-if="Object.keys(data).length > 0" style="padding-top: 200px;">
            <div style="text-align: center;margin-bottom: 15px;">
              <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
            </div>
            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 47px;
                        text-align: center;
                        letter-spacing: 0.015em;
                        margin-top: 10px;
                      ">
              KOMPETENSI YANG DILATIH
            </div>
            <div style="
                        font-family: Roboto;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        margin-top: 4px;
                      ">
              {{ data.program_name }}
            </div>
            <div style="
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: left;
                        margin-top: 30px;
                        display: flex;
                        justify-content: center
                      ">
              <div>
                <p style="font-weight: bold; font-size: 16px; text-align: center; margin-bottom: 10px; margin-top: 0px;">
                  Kompetensi</p>
                <div style="padding-left: 0px; margin-left: 0px;" v-for="(item, index) in data.competence" :key="index">
                  <p style="margin-bottom: 2px;font-size:14px;">
                    {{ index + 1 }}. {{ item }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import VueQrcode from '@chenfengyuan/vue-qrcode';
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  components: { VueHtml2pdf, VueQrcode },
  data() {
    return {
      certificateName: '',
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      is_prakerja: null,
      data: {},
      test: '10',
      member: {},
      program: '',
      competence: [],
      dialogCertif: false,
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      score: '',
      dateFinish: [],
      tgl: null
    };
  },
  computed: {
    // tgl() {
    //   return this.dateFinish[this.dateFinish.length - 1].created_at;
    // }

  },
  created() {
    this.getData();
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() {
  },
  methods: {
    getData() {
      this.false = true;
      this.axios
        // .get(`users/v1/member_redeem/detail/${this.$route.params.id}`,{headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }})
        .get(`users/v1/public/order/detail/${this.$route.params.id}`, { headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY } })
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + ' - ' + res.program_name
          setTimeout(() => {
            this.showCertif();
          }, 200)
        });
    },
    cancelAutoUpdate() { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    currentDateTime(a) {
      return moment(a).lang("nl").format("L");
    },
    currentDateTimes(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    convertHour(val) {
      let minutes = 0
      let hours = 0
      let last = ''
      if (val > 60) {
        hours = val / 60
        minutes = val % 60
        if (minutes > 0) {
          last = parseInt(hours) + ' Jam ' + minutes + ' Menit'
        } else {
          last = hours + ' Jam '
        }
      } else {
        minutes = val
        last = minutes + ' Menit'
      }
      return last
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getDetail(order) {
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail = res.program;
          console.log('data detail', this.detail)
          this.showCertif(this.id);
        });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
      // window.location.reload();
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

.style1 {
  padding-top: 100px;
  padding-bottom: 188px
}

.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}

.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}

.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}

.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}

@media print {
  @page {
    size: landscape;
  }
}
</style>
